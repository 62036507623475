.container {
  width: 226px;
  height: 436px;
  position: relative;
  margin: 0 auto;
}

.coupon-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.logo {
  position: absolute;
  top: -60px;
  right: -75px;
  width: 129px;
}

.free {
  margin: 0;
  padding-top: 17px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  font-size: 54.3487px;
  text-align: center;
}

.coffeetext {
  margin: 0;
  text-align: center;
  line-height: 32px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 30.2325px;
  letter-spacing: -2px;
}

.enjoytoday {
  margin: 0;
  margin-top: 2px;
  text-align: center;
  line-height: 25px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.01em;
}

.button-container {
  display: flex;
  justify-content: center;
}

.button {
  margin-top: 18px;
  border-radius: 9.06px;
  display: flex;
  justify-content: center;
  width: 165px;
  padding: 12px 16px;
  margin-right: 4px;
  font-weight: bold;
}

.button-validation {
  margin-top: 18px;
  border-radius: 9.06px;
  display: flex;
  justify-content: center;
  width: 100px;
  padding: 12px 16px;
  margin-right: 7.2px;
  font-weight: bold;
}

.toggle {
  text-align: center;
  margin-top: 36px;
  margin-right: 14px;
}

.limited {
  margin: 0;
  margin-top: -2px;
  text-align: center;
  line-height: 25px;
  font-family: 'Open Sans', sans-serif;
  font-size: 9.5px;
  font-weight: 400;
  letter-spacing: -0.01em;
}

.valid {
  margin: 0;
  margin-top: -10px;
  text-align: center;
  line-height: 25px;
  font-family: 'Open Sans', sans-serif;
  font-size: 9.5px;
  font-weight: 400;
  letter-spacing: -0.01em;
}

.topbar {
  margin-top: 120px;
}

.barcode {
  margin-top: 36px;
  margin-left: 29px;
}

.cup {
  margin-top: 34px;
  margin-left: 38px;
}

.not-found {
  text-align: center;
}